import {createStore} from 'vuex';
import {tokenRegistrator} from '@/ProjectInjections/MobileAppInjection';
import pagesInject from '@/ProjectInjections/localPagesInject';

const curUrl = new URL(window.location.href);
let serverPort = '';
let isExperementsProd = false;


let devSettings = require.context('@/assets', true, /dev_settings\.js$/);
devSettings = devSettings.keys()[0] == null ? null : devSettings(devSettings.keys()[0]);
if (devSettings != null && devSettings.SERVER_PORT != null) serverPort = devSettings.SERVER_PORT;
if (devSettings != null && devSettings.EXPEREMENTS_PROD != null) isExperementsProd = devSettings.EXPEREMENTS_PROD;


const AUTOSTART_MODE = curUrl.searchParams.get('autostart') != null;


export const ROOT_URL = curUrl.protocol + '//' + curUrl.hostname + serverPort;
export const IS_EXPEREMENTS_PROD = isExperementsProd;
export const MAX_ERROR_TEXT_LEN = 150;

function pageSorter(aPage, bPage) {
  if (aPage.internal_page && !bPage.internal_page) return 1;
  if (!aPage.internal_page && bPage.internal_page) return -1;

  const aPrior = aPage.priority ? aPage.priority : 0;
  const bPrior = bPage.priority ? bPage.priority : 0;

  if (aPrior < bPrior) return -1;
  if (aPrior > bPrior) return 1;

  if (String(aPage.name).toLowerCase() < String(bPage.name).toLowerCase()) return -1;
  if (String(aPage.name).toLowerCase() > String(bPage.name).toLowerCase()) return 1;
  return 0;
}

function getFastPanelStruct(sitePages) {
  const ans = Object.values(sitePages).filter((elem) => elem.in_fast_panel);

  ans.sort(pageSorter);
  return ans;
}

function getNormalisedPagesStruct(rawPages) {
  const ans = {};

  rawPages.sort(pageSorter);

  rawPages.forEach((elem) => {
    ans[elem.uid] = elem;
    ans[elem.uid].children = [];
  });

  rawPages.forEach((elem) => {
    if (!elem.hasOwnProperty('fullUrl')) {
      Object.defineProperty(elem, 'fullUrl', {get: function() {
        if (this.page_type == 'ext_link') {
          return this.url;
        }
        return (
          this.parent_page != null ?
          this.parent_page.fullUrl :
          (!this.internal_page ? '/ext_page/' : '/')
        ) + this.url + '/';
      }});
    }

    if (ans[elem.parent_page_uid] == null) {
      elem.parent_page_uid = null;
      return;
    }
    ans[elem.parent_page_uid].children.push(elem);
    elem.parent_page = ans[elem.parent_page_uid];
  });

  return ans;
}

const store = createStore({
  state: {
    critical_error: null,
    user_obj: null,
    fast_panel_struct: null,
    web_site_struct: null,
    current_page_info: null,
    autostart_mode: AUTOSTART_MODE,
    theme_type: 'dark',
    isMobile: false,
    screenMode: {
      screenMode: 'twoScreen',
      isLandscape: false,
    },
  },
  getters: {
    isLoggedIn: (state, getters) => {
      return state.user_obj != null && !state.user_obj.is_anonymous;
    },
    webSitePagesRoot: (state, getters) => {
      return Object.values(state.web_site_struct).filter((elem) => elem.parent_page_uid == null).sort(pageSorter);
    },
    isUserOwner: (state, getters) => {
      return state.user_obj?.is_owner;
    },
  },
  mutations: {
    screenModeMut(state, screenMode) {
      state.screenMode = screenMode;
    },
    is_mobile_mut(state, isMobile) {
      state.isMobile = isMobile;
    },
    critical_error_mut(state, {type, shortInfo, fullInfo}) {
      state.critical_error = {
        type: type,
        shortInfo: shortInfo,
        fullInfo: fullInfo,
      };
    },
    setTheme(state, newTheme) {
      state.theme_type = newTheme;
      try {
        localStorage.setItem('theme_type', newTheme);
      } catch {}
      document.getElementsByTagName('body')[0].className = newTheme;
    },
    user_obj_mut(state, data) {
      state.user_obj = data;
      if (!data.is_anonymous) tokenRegistrator();
    },
    web_site_struct_mut(state, data) {
      pagesInject(data, state.user_obj);
      state.web_site_struct = getNormalisedPagesStruct(data);
      state.fast_panel_struct = getFastPanelStruct(state.web_site_struct);
    },
    current_page_info_mut(state, data) {
      state.current_page_info = data;
    },
  },
  actions: {
  },
  modules: {
  },
});

function winResizeListener() {
  store.commit('is_mobile_mut', window.innerWidth <= window.transitionThreshold1);
}

window.addEventListener('resize', winResizeListener);

setTimeout(winResizeListener, 1);

export default store;
