<template>
  <!-- <div class="table-buttons-wrapper">
    <ButtonComponent v-if="isXlsxExporting"
                     class="table-export-button"
                     :label="'Сохранить в Excel эту таблицу'"
                     elementType="hover"
                     elementStatus="selected"
                     iconUrl="/icons/system/file-arrow-down.svg"
                     iconPosition="left"
                     @click_to_button="createXlsxTable"/>
  </div> -->
  <table class="table-data"
         ref="table"
         :style="{padding: `${padding}`}"
         :is_selectable="isSelectable ? isSelectable : undefined"
         :is_compact="isCompactTable ? isCompactTable : undefined">
    <!--  Заголовки  -->
    <tr class="table-data__header-row">
      <th v-for="item in currentColumns" :key="item"
          :style="{width: item.width}"
          class="table-data__header-cell"
          :class="{'table-data__header-cell_hidden': hideHeader}">

        <div class="table-data__header-cell__container"
             :class="getAlign(item)">
          <LabelComponent class="table-data__header-cell__container__label grow"
                          :class="[getHeaderLabelClass(item)]"
                          :style="{
                            'cursor': hideHeader ? 'default' : null,
                            'padding-right': !item.title.length ? '0' : null,
                          }"
                          label_type='caption/small/medium 10'
                          @click="setNewOrdering(item)"
                          :label_text="item.title"/>
          <!--  Данные для сортировки  -->
          <div class="table-data__header-cell__container__additional"
               v-show="getOrderPriority(item) !== undefined && !hideHeader">
            <ButtonComponent class="table-data__header-cell__container__additional__undo_sort_icon"
                             elementType="hover"
                             iconUrl="/icons/system/arrow-up-down-crossed-out.svg"
                             iconSize="12"
                             iconMargin="0"
                             v-show="getOrderPriority(item) !== undefined"
                             @click="setNewOrdering(item, true)"/>

            <ButtonComponent elementType="hover"
                             iconUrl="/icons/system/arrow-filled-left.svg"
                             iconSize="12"
                             iconPosition="right"
                             iconMargin="0"
                             v-show="getOrderPriority(item) !== undefined"
                             @click="setNewOrdering(item)"
                             :label="(currentColumnsOrderingPriority.length === 1) ? '' :
                                Math.abs(getOrderPriority(item))"
                             :iconRotate="getSign(getOrderPriority(item)) * -90"/>
          </div>
        </div>
      </th>
    </tr>
    <!--  Строка для вывода суммы  -->
    <tr v-if="(getShowTotalRow) && currentColumns.length > 1">

      <th v-for="item in currentColumns" :key="item"
          class="table-data__header-cell">

        <div class="table-data__header-cell__container__total"
             :class="getAlign(item)"
             @click="sumColumn(item)">

          <LabelComponent label_type='caption/small/medium 10'
                          :label_text="'Итого' + ': '"
                          v-show="item.id === 0"/>

          <template v-if="item.id !== 0">
            <LabelComponent label_type='caption/small/medium 10'
                            v-if="columnIsSumable(item.id) && !getColumnTotal(item)"
                            label_text="Σ"/>

            <LabelComponent label_type='caption/small/medium 10'
                            :is_monospace="true"
                            :digits_precision="2"
                            :label_text="getColumnTotal(item)"/>
          </template>
        </div>
      </th>
    </tr>

    <tr v-if="isLoading">
      <td colspan="1000">
        <!-- <LoadingFrame :class="getLoadingFrameClasses"/> -->
      </td>
    </tr>

    <tr v-else-if="tableData.length===0 && !isLoading && noDataText != null">
      <td class="table-data__main-cell" colspan="1000">
        <LabelComponent :label_text="noDataText"/>
      </td>
    </tr>

    <tr v-else v-for="itemRow in getCurrentData" :key="itemRow" class="table-data__main-row"
        :class="getRowClasses(itemRow, selectedRow)">
      <td v-for="itemColumn in currentColumns" :key="itemColumn"
          class="table-data__main-cell"
          :class="getAlign(itemColumn)"
          @click.stop="itemColumn.self_clickable !== true ? setSelectedRow(itemRow) : ''">

        <slot :row_id="itemRow.id"
              :column_id="itemColumn.id">
        </slot>

      </td>
      <div class="table-data__main-row__underline"/>
    </tr>
  </table>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue';
// import builderHook from '@/assets/v1/js/hooks/builderHook';
import useObserver from '@/assets/observer';

// import XlsxTable from '@/assets/v1/js/packs/tableDownloader';

import LabelComponent from '@/units/RichLabel.vue';
// import LoadingFrame from '../LoadingFrame';
import ButtonComponent from '@/units/forms/RichButton.vue';


const currentColumnsOrderingPriority = ref();
const currentColumns = ref([]);
const currentData = ref([]);
const selectedRow = ref();

const page = ref(1);
const size = ref(20);
const table = ref();

// const sortedData = ref([]);


const props = defineProps({
  tableColumns: {
    required: true,
    type: Array,
    default: () => [],
  },
  tableColumnsOrdering: {
    required: false,
    type: Array,
    default: () => [],
  },
  tableData: {
    required: true,
    type: Array,
    default: () => [],
  },
  tableDataTotal: {
    required: false,
    type: Object,
  },
  tableDataFormatted: {
    required: false,
    type: Array,
    default: () => [],
  },
  isXlsxExporting: {
    required: false,
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  selectedRowProps: {
    type: Number,
    default: undefined,
  },
  isSelectable: {
    type: Boolean,
    default: false,
  },
  isCompactTable: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    required: false,
  },
  noDataText: {
    type: String,
    default: 'нет данных',
    required: false,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  defaultOrderColumn: {
    type: Number,
    default: 0,
  },
  withTotal: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(['orderingChanged', 'selectChanged', 'selectChangedByClick', 'close_view']);

// const {loc} = builderHook();

const observe = () => {
  if (currentData.value.length >= size.value) {
    page.value++;
  }
};

useObserver(table, observe);


const getCurrentData = computed(() => {
  return currentData.value.slice(0, size.value * page.value);
});

// const getLoadingFrameClasses = computed(() => {
//   return {'table-has-data': !!props.tableData.length};
// });

const getShowTotalRow = computed(() => {
  return props.withTotal; // props.tableColumns.some((item) => item.is_with_sum) || (props.tableDataTotal && props.tableDataTotal.is_total);
});

// const getTotalRow = computed(() => {
//   let totalRow = {};

//   currentColumns.value.forEach((column) => {
//     let totalValue;

//     if (column.id === 0) {
//       totalValue = 'Итого' + ': ';
//     } else {
//       totalValue = getColumnTotal(column);
//     }

//     totalRow = {...totalRow, [column.id.toString()]: totalValue};
//   });

//   return totalRow;
// });


const sumColumn = (column) => {
  if (!columnIsSumable(column.id)) {
    return;
  }

  column.is_with_sum = !column.is_with_sum;
};

const columnIsSumable = (columnId) => {
  if (columnId < 1) {
    return false;
  }

  // eslint-disable-next-line guard-for-in
  for (const colNum in currentData.value) {
    if (typeof(currentData.value[colNum][columnId]) == 'number') {
      return true;
    }
  }

  return false;
};

const getHeaderLabelClass = (item) => {
  return (getAlign(item) !== undefined && getOrderPriority(item) === undefined) ?
      `table-data__header-cell__container__label__${getAlign(item)}` : '';
};

function copyObject(obj) {
  const isArray = (check) => check.constructor === Array;
  const isObject = (check) => check.constructor === Object;
  let ans = obj;

  if (isArray(obj)) {
    ans = obj.map((item, index) => item);
  } else if (isObject(obj)) {
    ans = Object.entries(obj);
    ans = ans.map(([key, value]) => [key, value]);
    ans = Object.fromEntries(ans);
  }
  return ans;
}

const setData = () => {
  currentColumns.value = props.tableColumns || [];
  currentData.value = copyObject(props.tableData || []);

  if (currentColumnsOrderingPriority.value === undefined) {
    currentColumnsOrderingPriority.value = props.tableColumnsOrdering || [];
  }

  currentColumns.value.forEach((itemColumn) => {
    const fullCellCount = currentData.value.filter((itemRow) => itemRow[itemColumn.id] !== '').length;
    itemColumn['is_empty'] = fullCellCount === 0;
  });

  currentColumns.value = currentColumns.value.filter((item) => !(item.is_delete_if_empty && item.is_empty));
  currentColumns.value.forEach((column) => {
    sortArray(column);
  });
};

const setNewOrdering = (column, isUndo = false) => {
  /* const columnOrdering = currentColumnsOrderingPriority.value.filter(
  (item) => column.id === Math.abs(item))[0];
  if (columnOrdering === undefined) {
    if (!isUndo) currentColumnsOrderingPriority.value.unshift(column.id);
  } else {
    currentColumnsOrderingPriority.value =
        currentColumnsOrderingPriority.value.filter((item) => column.id !== Math.abs(item));
    if (!isUndo) currentColumnsOrderingPriority.value.unshift(-columnOrdering);
  } */

  if (isUndo) {
    currentColumnsOrderingPriority.value.length = 0;
  } else {
    if (currentColumnsOrderingPriority.value.length > 0 &&
        column.id === Math.abs(currentColumnsOrderingPriority.value[0])) {
      currentColumnsOrderingPriority.value[0] = -currentColumnsOrderingPriority.value[0];
    } else {
      currentColumnsOrderingPriority.value = [column.id];
    }
  }
  currentColumnsOrderingPriority.value=[...currentColumnsOrderingPriority.value];
  sortArray(column);
  page.value = 1;
};

const setSelectedRow = (item) => {
  if (props.isSelectable) {
    emits('selectChangedByClick', item.id);
    selectedRow.value = item.id;
  }
};

const sortArray = (column) => {
  currentData.value = JSON.parse(JSON.stringify(props.tableData || []));
  if (column.sort_type && column.sort_type === 'datetime') {
    sortArrayDatetime();
  } else if (column.sort_type && column.sort_type === 'number') {
    sortArrayNumber();
  } else {
    sortArrayText();
  }
};

const sortArrayNumber = () => {
  sortArrayFunction((itemA, itemB) => {
    const orderingPriority = currentColumnsOrderingPriority.value.map((item) => item);
    return (orderingPriority * itemA > itemB) > 0;
  });
};

const sortArrayDatetime = () => {
  sortArrayFunction((itemA, itemB) => {
    const orderingPriority = currentColumnsOrderingPriority.value.map((item) => item);
    return orderingPriority * (new Date(itemA)).getTime() > (new Date(itemB)).getTime() > 0;
  });
};

const sortArrayText = () => {
  // eslint-disable-next-line max-params
  const sortRule = (itemA, itemB, sortDirection, paramName) => {
    let returnValue = 0;
    if (itemA[paramName] > itemB[paramName]) {
      returnValue = -1;
    } else if (itemB[paramName] > itemA[paramName]) returnValue = 1;

    return sortDirection * returnValue;
  };

  const sortRulePriority = (itemA, itemB, listPriority) => {
    const idSort = listPriority.shift();

    let returnValue = sortRule(
        itemA,
        itemB,
        getSign(idSort),
        Math.abs(idSort),
    );
    if (returnValue === 0) {
      returnValue = sortRulePriority(
          itemA,
          itemB,
          listPriority,
      );
    }
    return returnValue;
  };
  sortArrayFunction((itemA, itemB) => {
    const orderingPriority = currentColumnsOrderingPriority.value.map((item) => item);
    return sortRulePriority(
        itemA,
        itemB,
        orderingPriority,
    );
  });
};

const sortArrayFunction = (sortCallback) => {
  currentData.value.sort(sortCallback);
};

const getSign = (value) => {
  let sign = Math.sign(value);
  if (sign === 0) sign = Math.sign(1 / value);
  return sign;
};

const getOrderPriority = (item) => {
  let position = currentColumnsOrderingPriority.value.indexOf(item.id);
  if (position === -1) position = currentColumnsOrderingPriority.value.indexOf(-item.id);
  if (position === -1) return undefined;

  const sign = getSign(currentColumnsOrderingPriority.value[position]);
  position = sign * (position + 1);

  return position;
};

const getColumnTotal = (column) => {
  if (props.tableDataTotal && props.tableDataTotal[column.id]) {
    return props.tableDataTotal[column.id];
  }

  if (!column.is_with_sum) return '';
  if (currentData.value === undefined || currentData.value.length === 0) return '';
  // if (typeof currentData.value[0][column.id] !== typeof 1) return '';

  const reducer = (accumulator, currentValue) => accumulator + (typeof(currentValue) != 'number' ? 0 : currentValue);
  return currentData.value.map((item) => item[column.id]).reduce(reducer, 0);
};

const getAlign = (column) => {
  // выравнивание в ячейке: если 'center' или 'right, то накидывем класс, иначе сработает left по undefined
  return [['center', 'right'].includes(column.content_align) ?
      `cell_align_${column.content_align}` : undefined];
};

const getRowClasses = (row, selected) => {
  // собираем классы для строки таблицы
  return {
    'table-data__main-row__selected': row.id === selected,
    // во время загрузки таблица используется класс с серым фоном
    'table-is-loading': props.isLoading,
    // подкраска строки если задан статус
    [`${row.background_color}__background`]: row.background_color,
    [`${row.text_color}__color`]: row.text_color,
  };
};

// const createXlsxTable = () => {
//   const Table = new XlsxTable(
//       currentColumns.value,
//       props.tableDataFormatted,
//       getShowTotalRow,
//       getTotalRow,
//   );
//   Table.download();
// };


onMounted(() => {
  if (props.selectedRowProps !== undefined) selectedRow.value = props.selectedRowProps;
  setData();
  if (props.defaultOrderColumn !== 0) {
    setNewOrdering(currentColumns.value[Math.abs(props.defaultOrderColumn) - 1], false);

    if (props.defaultOrderColumn < 0) {
      setNewOrdering(currentColumns.value[Math.abs(props.defaultOrderColumn) - 1], false);
    }
  }
});


watch(currentColumnsOrderingPriority, (value) => {
  emits('orderingChanged', value);
}, {deep: true});

watch(() => props.tableData, () => setData());

watch(selectedRow, (value) => {
  if (selectedRow.value === props.selectedRowProps) return;
  // emits('selectedChanged', value);
});

watch(() => props.selectedRowProps, () => {
  selectedRow.value = props.selectedRowProps;
});
</script>

<style lang="less">
@loading__fullscreen__color: @dark-opacity-15;
@row__hover__background-color: @light-green-opacity-10;
@row__selected__text__color: @green;
@cell__border__color: @super-light;
@cell__text__color: @light-gray;

.table-buttons-wrapper {
  display: flex;
}

.table-export-button {
  margin-bottom: 5px;
}

.table-data {
  border-spacing: 0;
  //padding: 12px;
  //width: 100%;
  //border-collapse: collapse;
  table-layout: fixed;

  .cell_align {
    &_center {
      text-align: center;
      justify-content: center;
    }

    &_right {
      text-align: right;
      justify-content: flex-end;
    }
  }

  .table-is-loading {
    background-color: @loading__fullscreen__color;
  }

  .table-has-data {
    margin: 10px;
    position: fixed;
    z-index: 500;
  }

  &__header {
    &-row {
      position: relative;
      overflow: hidden;
      /* Fixed Headers */
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: @base-back;
      z-index: 50;
    }

    &-cell {
      //.flex(row, flex-start, flex-start);
      //.set_icon_color(@light-gray);
      position: relative;
      border-top: 1px solid @cell__border__color;
      border-bottom: 1px solid @cell__border__color;
      min-height: 29px;
      padding: 5px;
      color: @no-accent-text-color;

      &_hidden {
        color: transparent;
      }

      &__container {
        .flex(row, space-between, center);

        & > * {
          margin-left: 7px;
        }

        & > *:nth-child(1) {
          margin-left: 0;
        }

        text-align: left; // выравнивание заголовка влево по умолчанию (иначе будет по центру)

        &__label {
          padding-right: 26px;
          cursor: pointer;

          &__cell_align_right {
            padding-right: 0;
            padding-left: 26px;
          }

          &__cell_align_center {
            padding-right: 13px;
            padding-left: 13px;
          }
        }

        &__additional {
          position: absolute;
          right: 0;
          .flex(row, space-between, center);

          &__undo_sort_icon {
            visibility: hidden;
          }
        }

        &__total {
          color: @no-accent-text-color;
          text-decoration: underline;
          cursor: pointer;
          .flex(row, flex-start, center);

          & > * {
            margin-right: 5px;
          }

          & > *:last-child {
            margin-right: 0;
          }
        }
      }

      &:hover {
        .table-data__header-cell__container__additional__undo_sort_icon {
          visibility: visible;
        }
      }
    }
  }

  &__main {
    &-row {
      position: relative;

      &__underline {
        position: absolute;
        left: 0;
        bottom: 18px;
        right: 0;
        height: 0;
        border-bottom: 1px dotted @super-light;
        display: none;
      }

      // первая после заголовка строка таблицы без border-top
      &:nth-child(2) td {
        border-top: unset;
      }
    }

    &-cell {
      z-index: 1;
      border-top: 1px solid @cell__border__color;
      padding: 5px 5px;
      position: relative;
      vertical-align: top;
    }
  }

  &[is_selectable=true] {
    .table-data__main-row:hover {
      background: @row__hover__background-color;
      cursor: pointer;
    }

    .table-data__main-row__selected {
      color: @row__selected__text__color;

      .label-zero {
        color: @row__selected__text__color;
      }
    }
  }

  &[is_compact=true] {
    .table-data {
      &__header {
        &-row {

        }

        &-cell {
          padding: 4px 4px;
          border: 0 solid transparent;

          &__container__additional {
          }
        }
      }

      &__main {
        &-row {

        }

        &-cell {
          border-top: 0 solid transparent;
          padding: 6px 4px;
        }
      }
    }
  }
}
</style>
