<template>
  <div class="base-content-wrapper" :class="{'content-box': this.is_with_background}">
    <teleport
              :disabled="!(this.drawHeader && this.mergeHeaderWithContent)"
              :to="this.drawHeader && this.mergeHeaderWithContent ? '#content-header' : null"
            >

      <div class="base-content-header" :class="{'content-background': !this.is_with_background}">

        <Transition name="back_button__fade" appear>
          <ButtonComponent
            v-if="this.get_current_page_info"
            class="back_button"
            iconUrl="/icons/system/arrow-left.svg"
            iconMargin="0 8px 0 0"
            elementType="default"
            :href="this.get_parent_structure ? this.get_parent_structure.fullUrl : '/'"
            label="Назад" />
        </Transition>

        <div class="page-header" :class="this.mergeHeaderWithContent ? 'mobile-header' : null">
          <Transition name="page-title__fade" appear>
            <LabelComponent class="page-title"
                            label_type="h2"
                            :label_text="this.get_current_page_name" :key="this.get_current_page_name"/>
          </Transition>

          <div id="page-tools" v-show="this.$store.getters.isUserOwner">
            <ButtonComponent  v-if="this.get_current_page_info != null && !this.get_current_page_info.internal_page"
                              iconUrl="/icons/system/edit.svg"
                              elementType="default"
                              iconSize="27"
                              @click="this.openPageForm"
                              />
            <ButtonComponent  v-if="this.get_current_page_info != null && !this.get_current_page_info.internal_page"
                              iconUrl="/icons/system/trash.svg"
                              elementType="default"
                              iconSize="27"
                              iconColor="#ee6767"
                              @click="this.removePage"
                              />
          </div>
        </div>

      </div>
    </teleport>

    <div class="for-scroll-wrapper grow">
      <div
          class="content__slider"
          id="content-slider">

        <div id="content-header"></div>

        <div id="page-content"></div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import isMobileMixin from '@/assets/mixins/isMobileMixin.js';
// import BlockSeparator from '@/units/forms/BlockSeparator.vue';

import PageForm from '@/windows/PageForm.vue';

import deletePage from '@/assets/dataSources/navigation/deletePage.js';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    // BlockSeparator,
  },
  data: ()=>({
    drawHeader: false,
  }),
  watch: {
    get_current_page_info() {
      if (this.get_current_page_info != null) return;
    },
  },
  mounted() {
    this.$nextTick(() => this.drawHeader = true);
  },
  mixins: [isMobileMixin],
  methods: {
    openPageForm() {
      window.openWindow({
        caption: 'Редактирование страницы',
        component: PageForm,
        componentProps: {
          page_uid: this.get_current_page_info.uid,
          page_type: this.get_current_page_info.page_type,
          initial_name: this.get_current_page_info.name,
          initial_icon: this.get_current_page_info.icon_url,
          initial_parent_id: this.get_current_page_info.parent_page_uid,
          initial_open_new_tab: this.get_current_page_info.open_new_tab,
          initial_enable_diagram_preview: this.get_current_page_info.enable_diagram_preview,
          initial_can_edit_storage: this.get_current_page_info.can_edit_storage,
          initial_in_fast_panel: this.get_current_page_info.in_fast_panel,
          initial_url: this.get_current_page_info.url,
        },
      });
    },
    async removePage() {
      const selectResult = await window.openYNWindow(
          'Вы уверены что хотите удалить эту и все дочерние страницы?\nЭто действие нельзя будет отменить!',
          'Удаление страницы',
      );

      if (selectResult != 'yes') return;

      deletePage(this.get_current_page_info.uid, () => {
        getWebSiteStructure((result) => {
          this.$store.commit('web_site_struct_mut', result);
          this.$router.replace({
            path: this.get_current_page_info.parent_page == null ? '/' : this.get_current_page_info.parent_page.fullUrl,
          });
        });
      });
    },
  },
  computed: {
    mergeHeaderWithContent() {
      return this.isLandscape || this.isMobWidth;
    },
    get_current_page_info() {
      return this.$store.state.current_page_info;
    },
    get_parent_structure() {
      return this.get_current_page_info ? this.get_current_page_info.parent_page : null;
    },
    get_current_page_name() {
      if (this.name) return this.name;
      const currentPage = this.get_current_page_info;
      if (currentPage) return currentPage.name;
      return null;
    },
  },
  props: {
    name: null,
    is_with_background: {
      type: Boolean,
      default: true,
    },
    // fixedMobileHeader: {
    //   type: Boolean,
    //   default: false,
    // },
  },
};

</script>

<style lang="less">
#page-tools {
  .flex(row, center, center);
}

.back_button{
  align-self: flex-start;

  &__fade-enter-active,
  &__fade-leave-active {
    transition: max-height 0.3s, opacity 0.3s;
    max-height: 16px;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    max-height: 0px;
    opacity: 0;
  }
}

.page-header {
  .flex(row, space-between, end);
}

#content-header {
  .flex(column, flex-start, stretch);
  padding-left: 14px;
}

.base-content-header{
  .flex(column, flex-start, stretch);
  padding: @base-gap;
  margin-right: 0;
  margin-bottom: @base-gap;
}

.base-content-wrapper{
  .flex(column, flex-start, stretch);
  height: 100%;
  width: 100%;

  .page-title{
    &__fade-enter-active,
    &__fade-leave-active {
      transition: opacity 0.3s;
    }

    &__fade-enter-from,
    &__fade-leave-to {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }

  .for-scroll-wrapper {
    position: relative;
  }

  .content{
    &__slider{
      max-width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;

      #page-content{
        width: 100%;
      }

      transition: background 0.3s;
    }
  }
}


@media screen and (max-width: @tablet-width) {
  .back_button {

    &__fade-enter-active,
    &__fade-leave-active {
      transition: padding 0.3s;
      max-height: 30px;
    }

    &__fade-enter-from,
    &__fade-leave-to {
      max-height: 0px;
    }
  }

  #content-header {
    padding-left: 0;
  }

  .base-content-header{
    // .flex(column, flex-start, stretch);
    // padding: @base-gap;
    margin-right: 0;
    // margin-bottom: @base-gap;
  }
}
</style>
