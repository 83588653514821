function recalculateWinData(store) {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  const bodyElem = document.getElementsByTagName('body')[0];
  const bodyStyles = window.getComputedStyle(bodyElem, null);

  let screenMode = 'twoScreen';
  // const isLandscape = false;

  const landscapeHeight = bodyStyles.getPropertyValue('--landscape-height').slice(0, -2);

  [
    [parseInt(bodyStyles.getPropertyValue('--two-screen-width').slice(0, -2)), 'twoScreen'],
    [parseInt(bodyStyles.getPropertyValue('--extrim-screen-width').slice(0, -2)), 'extrimScreen'],
    [parseInt(bodyStyles.getPropertyValue('--very-large-screen-width').slice(0, -2)), 'veryLargeScreen'],
    [parseInt(bodyStyles.getPropertyValue('--large-screen-width').slice(0, -2)), 'largeScreen'],
    [parseInt(bodyStyles.getPropertyValue('--big-screen-width').slice(0, -2)), 'bigScreen'],
    [parseInt(bodyStyles.getPropertyValue('--desktop-width').slice(0, -2)), 'desktopScreen'],
    [parseInt(bodyStyles.getPropertyValue('--tablet-width').slice(0, -2)), 'tabletScreen'],
    [parseInt(bodyStyles.getPropertyValue('--mobile-width').slice(0, -2)), 'mobileScreen'],
  ].forEach((elem) => {
    if (vw <= elem[0]) screenMode = elem[1];
  });

  // window.SCREEN_MODE = screenMode;
  // window.IS_LANDSCAPE = vh <= landscapeHeight;

  store.commit('screenModeMut', {
    screenMode: screenMode,
    isLandscape: vh <= landscapeHeight,
  });

  // window.UPDATE_SCREEN_MODE.forEach((elem) => elem());
}


export default async (store) => {
  // window.UPDATE_SCREEN_MODE = [];
  window.addEventListener('resize', () => recalculateWinData(store));
  recalculateWinData(store);
};
