<template>
  <div class="report-tile">
    <linkTo :href="this.loc_data.fullUrl" :newTab="this.loc_data.open_new_tab === true" class="report-tile-content">

      <div class="left-block">
        <div class="icon-wrapper">
          <IconComponent size=37 :url="loc_data.icon_url"/>
        </div>

        <div class="title-block">
          <LabelComponent
                          label_type='h3'
                          :label_text="loc_data.name"/>
          <LabelComponent v-if="loc_data.description"
                          class="title-block__sign"
                          label_type='body/large/regular 14'
                          :label_text="loc_data.description"/>
        </div>
      </div>

      <div class="right-block">
        <IconComponent url="/icons/system/caret-circle-down.svg" size="24" class="report-tile-icon"/>
      </div>
    </linkTo>
  </div>
</template>

<script type="text/javascript">

import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';
import linkTo from '@/units/linkTo.vue';

export default {
  components: {
    IconComponent,
    LabelComponent,
    linkTo,
  },
  props: ['loc_data'],
};


</script>

<style lang="less">
@report-tile__text__hover__color: @light-green;
@report-tile__icon__color: @green;

@report-tile__next-arrow__icon__color: @green;

.report-tile{
  .report-tile-content{
    .flex(row,  space-between, center);
    transition: color 0.2s;

    overflow-wrap: anywhere;

    padding: 21px 24px;

    width: 100%;
    flex-grow: 1;


    &:hover{
      color: @report-tile__text__hover__color;
    }

    .left-block{
      .flex(row,  flex-start, center);

      .icon-wrapper{
        min-width: 52px;
        max-width: 52px;
        min-height: 52px;
        max-height: 52px;
        .flex(row,  center, center);
        .set_icon_color(@report-tile__icon__color);

        margin-right: 15px;
      }

      .title-block {
        .flex(column, center, flex-start);
        max-width: 100%;
        margin: 0;

        & > * {
          margin-bottom: 2px;
        }
        & > *:last-child {
          margin-bottom: 0;
        }

        &__sign{
          transition: color 0.2s;

          .set_color(@light-gray)
        }
      }
    }
    .right-block{
      .set_icon_color(@report-tile__next-arrow__icon__color);

      .report-tile-icon {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
