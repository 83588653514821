<template>
  <div id="base_app" v-if="false">
    <div class="right-column"
         id="content-modal"
         ref="frameClickMouseRightColumn">
      <div class="top-panel">
        <TopPanel @left-menu="this.open_left_column"/>
      </div>
      <div class="content-panel">
        <BaseContentWrapper
            :name="this.name"
            :is_with_background="this.is_with_background"
            >
        </BaseContentWrapper>
      </div>
      <div class="bottom-panel">
        <BottomPanel/>
      </div>
    </div>
  </div>
  <div id="base_app" v-else>
    <div class="small-left-column">
      <div class="open-left-burger"><IconComponent class="icon-over-transparency-color"
                     @click="this.open_left_column"
                     url="/icons/system/burger.svg"
                     size="28"/></div>
    </div>
    <div class="left-column-background fixed-full-screen-block" :opened="this.leftColumnOpened" @click="this.close_left_column()"/>
    <div class="left-column" :opened="this.leftColumnOpened">
      <div class="page-logo-wraper" v-if="!this.isLandscape">
        <MainLogo class="page-logo"
                  branch=""
                  colorStyle="white-green"
                  width="300"
                  @click_to_logo="this.toLogoClick"/>
      </div>
      <LeftMenu />
    </div>
    <div class="right-column">
        <div class="top-panel">
          <div class="open-left-burger"><IconComponent class="icon-over-transparency-color"
                     @click="this.open_left_column"
                     url="/icons/system/burger.svg"
                     size="28"/></div>
          <TopPanel />
        </div>
        <div class="content-panel">
          <BaseContentWrapper
            :name="this.name"
            :is_with_background="this.is_with_background"
            >
          </BaseContentWrapper>
        </div>
        <div class="bottom-panel">
          <BottomPanel />
        </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import TopPanel from './topPanel/TopPanel.vue';
import LeftMenu from './navigationPanel/LeftMenu.vue';
import BottomPanel from './bottomPanel/BottomPanel.vue';
import BaseContentWrapper from '@/layoutTools/BaseContentWrapper.vue';
import MainLogo from '@/units/logo/MainLogo.vue';
import IconComponent from '@/units/RichIcon.vue';
import isMobileMixin from '@/assets/mixins/isMobileMixin.js';

export default {
  components: {
    BaseContentWrapper,
    LeftMenu,
    MainLogo,
    TopPanel,
    BottomPanel,
    IconComponent,
  },
  mixins: [isMobileMixin],
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) return;
      this.close_left_column();
    },
  },
  data: ()=>({
    leftColumnOpened: false,
  }),
  computed: {
  },
  methods: {
    toLogoClick() {
      this.$router.push('/');
    },
    open_left_column() {
      this.leftColumnOpened = true;
    },
    close_left_column() {
      this.leftColumnOpened = false;
    },
  },
  computed: {
    name() {
      return this.ext_page_name == null ? this.$route.meta.viewName : this.ext_page_name;
    },
    is_with_background() {
      return this.$route.meta.witoutBackground != true;
    },
  },
  props: {
    ext_page_name: null,
  },
};

</script>

<style lang="less">
@left-col-width: 260px;
@top-bottom-panel-height: 60px;

#base_app {
  position: relative;
  width: 100%;
  height: 100%;
  grid-template-columns:@left-col-width 1fr;
  // background-color: aqua;
  display: grid;

  .page-logo-wraper {
    width: 100%;
  }

  .small-left-column {
    background: @top-panel-background;
      display: none;
  }

  .left-column-background {
    display: none;
    backdrop-filter: blur(4px);
    // transition: background @slow-animation-speed;
    background: transparent;
    z-index: 1;
  }

  .left-column {
      background: @mobile-left-column-background;
      transition: left 200ms;
      z-index: 1;
      .flex(column, flex-start, flex-start);
      height: 100%;
  }

  .open-left-burger {
    &:hover { cursor: pointer; }
    // .flex(row, center, center);
    display: none;
    // height: min-content;
    // width: min-content;
    margin: @base-gap;
  }

  .right-column {
      // background-color: black;
      grid-template-rows:@top-bottom-panel-height 1fr;
      display: grid;

      .content-panel {
        padding: @small-gap;
      }
  }

  .bottom-panel {
    display: none;
    background: @bottom-panel-background;
  }

  .top-panel {
    .flex(row, center, center);
    background: @top-panel-background;
  }

  @media (max-width: @tablet-width) and (min-height: @landscape-height) {
    grid-template-columns: 1fr;

    .left-column {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -@left-col-width;
        width: @left-col-width;
        // background-color: rgb(128, 0, 128, 1);
    }

    .open-left-burger {
      display: flex;
    }

    .left-column-background[opened=true] {
      display: block;
      background: @fade-content-background-color
    }

    .left-column[opened=true] {
        left: 0;
    }

    .right-column {
        grid-template-rows:@top-bottom-panel-height 1fr @top-bottom-panel-height;
    }

    .bottom-panel {
        display: block;
    }
  }

  @media (max-width: @tablet-width) and (max-height: @landscape-height) {
      grid-template-columns:@top-bottom-panel-height 1fr;

      .small-left-column {
          display: block;
      }

      .left-column {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -@left-col-width;
          width: @left-col-width;
          // background-color: rgb(128, 0, 128, 1);
      }

      .open-left-burger {
        display: flex;
      }

      .left-column[opened=true] {
          left: 0;
      }

      .left-column-background[opened=true] {
        display: block;
        background: @fade-content-background-color
      }

      .right-column {
          grid-template-rows: 1fr;
      }

      .top-panel {
          display: none;
      }
  }

  @media (min-width: @tablet-width) and (max-height: @landscape-height) {
      .right-column {
          grid-template-rows: 1fr;
      }

      .top-panel {
          display: none;
      }
  }
}

</style>
