export default {
  computed: {
    isMobWidth() {
      return (
        this.$store.state.screenMode.screenMode == 'tabletScreen' ||
        this.$store.state.screenMode.screenMode == 'mobileScreen'
      );
    },
    isLandscape() {
      return this.$store.state.screenMode.isLandscape;
    },
  },
};
