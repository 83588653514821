<template>
  <div class="topPanelFrame">
    <div class="top-wrapper">
      <!-- <IconComponent class="topPanelFrame__burger icon-over-transparency-color"
                     @click="burgerClick"
                     url="/icons/system/burger.svg"
                     size="28"/> -->

      <IconComponent @click="this.setTheme(this.selectedTheme + 1)"
                     :url="this.themes[this.selectedTheme].icon"
                     class="icon-over-transparency-color"
                     size="28"/>

       <!--<SwitcherComponent ref="switcher"
                         size="medium"
                         :values="this.get_lang_switcher_values"
                         :init_selected_index="this.getUserLangId()"
                         @select_changed="this.switchLanguage"/>-->
    </div>
    <ProfileFrame v-if="!this.$store.state.autostart_mode"/>
  </div>
</template>

<script>

// import builder from '../@/assets/v1/js/builder';
import ProfileFrame from './ProfileFrame.vue';
import IconComponent from '@/units/RichIcon.vue';
// import SwitcherComponent from '../../unit/Switcher';
// import ButtonComponent from '@/units/forms/RichButton.vue';


export default /* builder */({
  name: 'TopPanel',
  components: {
    IconComponent,
    ProfileFrame,
    // SwitcherComponent,
    // ButtonComponent,
  },
  data: () => ({
    themes: [
      {
        value: 'old',
        icon: '/icons/system/half_circle.svg',
      },
      {
        value: 'dark',
        icon: '/icons/system/moon.svg',
      },
      {
        value: 'light',
        icon: '/icons/system/sun.svg',
      },
    ],
    selectedTheme: 0,
  }),
  mounted() {
    this.themes.forEach((elem, idx) => {
      if (elem.value == this.$store.state.theme_type) this.selectedTheme = idx;
    });
  },
  methods: {
    // burgerClick() {
    //   this.$emit(
    //       'left-menu',
    //       'on',
    //   );
    // },
    setTheme(idx) {
      if (idx >= this.themes.length) idx = 0;
      this.selectedTheme = idx;
      this.$store.commit('setTheme', this.themes[this.selectedTheme].value);
    },
  },
});


</script>

<style lang="less">
@panel__top_bottom__padding: @margin-horizontal-default;
@panel__top__padding__800: @margin-horizontal-800;
// @panel__burger__color: @gray;

.topPanelFrame{
  width: 100%;
  .flex(row, space-between, center);
  padding: 0 @panel__top_bottom__padding;
  // &__burger{
  //   display: none;
  // }
}

@media screen and (max-width: @tablet-width) {
  .lang-toggle{
    display: none;
  }

  .topPanelFrame{
    padding: 0 @panel__top__padding__800 0;
    .top-wrapper {
      .flex(row, center, center);

      & > * {
        margin-right: 7px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }
    // &__burger{
    //   display: block;
    //   // background-color: @no-accent-text-color;
    //   &:hover { cursor: pointer}
    // }
  }
}

</style>
